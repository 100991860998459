<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  mounted() {
    const savedToken = window.localStorage.getItem('serverToken');

    if (!savedToken) {
      this.$router.push('/login');
    } else {
      this.$store.commit('setServerToken', savedToken);
    }
  },
};
</script>

<style>
html {
  user-select: none;
  overscroll-behavior: none;
}

body {
  align-items: center;
  background-color: #000;
  display: flex;
  height: 100vh;
  margin: 0;
  position: fixed;
  width: 100vw;
}

img {
  pointer-events: none;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #2c3e50;
  /* height: 56.5234375vw; */
  height: 100%;
  width: 100%;
  text-align: center;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
