<template>
  <div class="wheel">
    <audio ref="aud" src="@/assets/spin.wav" />
    <div
      class="inner-circle"
      ref="innerCircle"
    >
      <div class="slide-button-container">
        <div
          class="slide-button"
          @click="toggleSlide"
        />
      </div>
      <div
        class="wheel-segment"
        v-for="segment in segments"
        :key="segment.index"
        ref="segments"
        @click="segmentClick(segment.index)"
      >
        <img
          class="segment"
          alt="segment"
          :src="'img/wheel-segments/goal-segment-' + (segment.index + 1) + '.svg'"
        />
        <img
          class="icon"
          alt="icon"
          :src="'img/wheel-icons/Icon' + (segment.index + 1) + '.png'"
        />
      </div>
    </div>
    <div v-if="showSlide" class="slide">
      <img
        alt="slide"
        :src="currentSlide"
      />
      <div
        class="button"
         @click="toggleSlide"
      />
    </div>
    <div v-if="showHolding" class="holding" @click="showHolding=false">
      <img src="@/assets/holding.png" alt="holding"/>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import axios from 'axios';

export default {
  data() {
    return {
      spinStart: null,
      spinSpeed: 1,
      wheelRotation: 0,
      selectedGoal: null,
      currentSlide: '',
      showSlide: false,
      showHolding: true,
      lastInteraction: Date.now(),
      segments: [
        {
          index: 0,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 1,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 2,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 3,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 4,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 5,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 6,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 7,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 8,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 9,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 10,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 11,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 12,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 13,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 14,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 15,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
        {
          index: 16,
          title: 'Goal Name',
          rotation: 0,
          x: 0,
          y: 0,
        },
      ],
    };
  },
  methods: {
    circleData() {
      const data = {
        x: this.$refs.innerCircle.offsetLeft + this.$refs.innerCircle.offsetWidth / 2,
        y: this.$refs.innerCircle.offsetTop + this.$refs.innerCircle.offsetHeight / 2,
        c: this.$refs.innerCircle.offsetWidth,
      };

      return data;
    },

    placeSegments() {
      const circleData = this.circleData();
      const angleOffset = 360 / this.segments.length;
      const offset = circleData.c * 0.75;

      this.segments.forEach((segmentData, i) => {
        const segment = this.$refs.segments[segmentData.index];
        const rotation = angleOffset * segmentData.index + this.wheelRotation;
        const x = offset * Math.cos((rotation - 90) * (Math.PI / 180));
        const y = offset * Math.sin((rotation - 90) * (Math.PI / 180));
        const xOffset = `${x}px`;
        const yOffset = `${y}px`;
        const rotate = `${rotation}deg`;

        this.segments[i].x = x;
        this.segments[i].y = y;
        this.segments[i].rotation = rotation;

        segment.style.transform = `
          translate(${xOffset}, ${yOffset}) rotate(${rotate})
        `;

        const icon = segment.childNodes[1];

        icon.style.transform = `
          rotate(-${rotate})
        `;
      });
    },

    setupSegments() {
      const x = window.innerWidth / 2;
      const y = window.innerHeight / 2;
      this.segments.forEach((segmentData) => {
        const segment = this.$refs.segments[segmentData.index];
        segment.style.top = `${y - segment.offsetHeight / 2}px`;
        segment.style.left = `${x - segment.offsetWidth / 2}px`;
      });
    },

    spin() {
      if (this.wheelRotation < 359) {
        this.wheelRotation += this.spinSpeed;
      } else {
        this.wheelRotation = 0;
      }
      this.placeSegments();

      if (Date.now() < this.spinStart + 5000) {
        setTimeout(() => {
          this.spin();
        }, 2);
      } else {
        this.finishSpin();
      }
    },

    startSpinning() {
      this.sendProjection('spin');

      this.setupSegments();

      if (!this.$store.state.spinning) {
        // eslint-disable-next-line no-restricted-syntax
        for (const segment of this.segments) {
          const element = this.$refs.segments[segment.index];
          element.style.opacity = 1;
        }
        this.spinStart = Date.now();
        this.$store.commit('setSpinning', true);
        this.spinSpeed = parseFloat((Math.random() * (2 - 1) + 1).toFixed(3));
        this.spin();
        this.$refs.aud.play();
      }
    },

    finishSpin() {
      this.$store.commit('setSpinning', false);
      const angleOffset = (360 / this.segments.length) / 2;
      const min = 360 - angleOffset;
      const max = angleOffset;

      // eslint-disable-next-line no-restricted-syntax
      for (const segment of this.segments) {
        const rotation = segment.rotation < 360 ? segment.rotation : segment.rotation - 360;

        if (rotation > min || rotation < max) {
          this.selectedGoal = segment;

          this.sendProjection();

          break;
        }
      }

      this.setGoal();
    },

    segmentClick(id) {
      if (!this.$store.state.spinning) {
        this.sendProjection(id);
        this.selectedGoal = this.segments[id];
        this.setGoal();
      }
    },

    sendProjection(id = null) {
      const url = `${process.env.VUE_APP_SERVER_ADDRESS}/api/set-projection/`;

      if (id === null) {
        id = this.selectedGoal.index;
      }

      axios.post(
        url,
        {
          video_id: id,
        },
        {
          headers: {
            Authorization: `Token ${this.$store.state.serverToken}`,
          },
        },
      );
    },

    setGoal() {
      this.$refs.innerCircle.style.backgroundImage = `
        url(img/inner-circles/Goal${this.selectedGoal.index + 1}_InnerCircle.png)
      `;
      this.setSlide();

      // eslint-disable-next-line no-restricted-syntax
      for (const segment of this.segments) {
        const element = this.$refs.segments[segment.index];
        if (segment.index === this.selectedGoal.index) {
          element.style.opacity = 1;
        } else {
          element.style.opacity = 0.2;
        }
      }
    },

    setSlide() {
      this.currentSlide = `
        img/slides/${this.selectedGoal.index + 1}.png
      `;
    },

    testScreensaver() {
      if ((Date.now() - this.lastInteraction > 90000) && !this.showHolding) {
        this.showHolding = true;
        this.sendProjection('standby');
      }

      setTimeout(() => {
        this.testScreensaver();
      }, 1000);
    },

    toggleSlide() {
      if (this.currentSlide) {
        this.showSlide = !this.showSlide;
      }
    },
  },

  mounted() {
    window.addEventListener('mousemove', () => {
      this.lastInteraction = Date.now();
      this.showHolding = false;
    });

    this.testScreensaver();

    // offsetHeight not accurate on first load, so wait a bit
    setTimeout(() => {
      this.setupSegments();
      this.placeSegments();
    }, 100);
  },
};
</script>

<style scoped>

.icon {
  left: 33%;
  position: absolute;
  top: 35%;
  width: 33%;
}

.inner-circle {
  background-position-x: center;
  background-size: cover;
  border-radius: 100%;
  height: calc(47.5vh);
  position: absolute;
  width: calc(47.5vh);
}

.wheel {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.wheel-segment {
  position: fixed;
}

.segment {
  width: calc(13.5vh);
}

.slide {
  align-items: center;
  background: #fff;
  display: flex;
  height: 100%;
  position: absolute;
  left: 0;
  top: 1;
  width:100%;
  z-index: 5;
}

.slide img {
  height: 56.5234375vw;
  width: 100%;
}

.slide .button {
  bottom: 10.869vh;
  height: 25vh;
  position: absolute;
  right: 0;
  width: 25vh;
}

.slide-button {
  height: 75%;
  width: 75%;
}

.slide-button-container {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.holding {
  align-items: center;
  background: #ffffffee;
  display: flex;
  height: 100%;
  position: absolute;
  width: 100vw;
  z-index: 1;
}

.holding img {
  height: 56.5234375vw;
  width: 100%;
}
</style>
